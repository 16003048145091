.basic-multi-select {
  &.border-danger {
    .select__control {
      border-color: #dc3545 !important;
    }
  }
  .select__control--is-focused {
    border-color: #ff6582 !important;
    box-shadow: none !important;
  }
}
