.profileNav {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
  .profile {
    min-height: 100px;
    min-width: 400px;
    padding: 10px 20px;
  }
}
