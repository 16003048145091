.vertical-center {
  min-height: 100%; /* Fallback for vh unit */

  /* Make it a flex container */
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  /* Align the bootstrap's container vertically */
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  /* In legacy web browsers such as Firefox 9
       we need to specify the width of the flex container */
  width: 100%;

  /* Also 'margin: 0 auto' doesn't have any effect on flex items in such web browsers
       hence the bootstrap's container won't be aligned to the center anymore.
    
       Therefore, we should use the following declarations to get it centered again */
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;

  &.fullpage {
    min-height: 100vh; /* You might also want to use
                            'height' property instead.
                            
                            Note that for percentage values of
                            'height' or 'min-height' properties,
                            the 'height' of the parent element
                            should be specified explicitly.
      
                            In this case the parent of '.vertical-center'
                            is the <body> element */
  }
}

.loader {
  position: relative;
  margin: 0px auto;
  width: 100px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}
.circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes color {
  100%,
  0% {
    stroke: $primary;
  }
  40% {
    stroke: $primary;
  }
  66% {
    stroke: $primary;
  }
  80%,
  90% {
    stroke: $primary;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $primary;
  }
  40% {
    stroke: $primary;
  }
  66% {
    stroke: $primary;
  }
  80%,
  90% {
    stroke: $primary;
  }
}
