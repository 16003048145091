@media all and (min-width: 480px) {
  .auth {
    padding: 15px;
    margin: 150px auto;
    max-width: 480px;

    .logo {
      margin: 0 auto 25px auto;
    }
  }
}
