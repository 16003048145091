.react-datepicker__close-icon::after {
  background-color: $primary;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: inherit;
  color: inherit;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover{
    background-color: darken($primary, 15%);
    color: $white;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $primary;
  color: $white;
}

// .react-datepicker__day--selecting-range-start:hover:not(.react-datepicker__day--in-range),
// .react-datepicker__month--selecting-range-start:hover:not(.react-datepicker__month--in-range),
// .react-datepicker__quarter--selecting-range-start:hover:not(.react-datepicker__quarter--in-range),
// .react-datepicker__year--selecting-range-start:hover:not(.react-datepicker__year--in-range),
// .react-datepicker__day--selecting-range-end:hover:not(.react-datepicker__day--in-range),
// .react-datepicker__month--selecting-range-end:hover:not(.react-datepicker__month--in-range),
// .react-datepicker__quarter--selecting-range-end:hover:not(.react-datepicker__quarter--in-range),
// .react-datepicker__year--selecting-range-end:hover:not(.react-datepicker__year--in-range)
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: darken($primary, 15%);
  color: $white;
}
