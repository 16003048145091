$primary: #e4002b;
$body-bg: #f0f0f0;

@import './fonts/custom-fonts';
@import '~bootstrap/scss/bootstrap';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import './shared/components/UIElements/Loading';
@import './shared/components/Navigation/Header';
@import './shared/components/Navigation/Profile';
@import './shared/components/Navigation/Qrcode';
@import './shared/components/UIElements/ItemsDateRange';
@import './shared/components/UIElements/ItemsTableSort';
@import './shared/components/Form/FileUpload';
@import './shared/components/UIElements/Recordings';
@import './shared/components/UIElements/ErrorModal';
@import './user/pages/Auth';
@import './user/pages/JoinRoom';
@import './homepage/components/Calendar';
@import './homepage/components/AsyncMultiselect';

body {
  font-family: 'Montserrat';
  font-weight: 400;
  background-color: $body-bg;
}

button,
input,
select,
div,
.btn {
  &:focus {
    outline: unset !important;
    box-shadow: unset !important;
  }
}

.navbar {
  padding: 0 1rem;
  &.navbar-dark {
    .navbar-toggler {
      border: none;
    }
  }
}

.table th,
.table td {
  padding: 0.75rem 0.25rem;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker {
  font-family: 'Montserrat';
  font-weight: 400;

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: $primary;
  }
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-position: right calc(0.675em + 0.1875rem) center;
}

.custom-switch {
  label {
    cursor: pointer;
  }
}

.custom-control{
  z-index: initial !important;
}
