.fc-button-primary {
  background-color: $primary;
  border-color: $primary;

  @include transition($btn-transition);

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $primary;
    border-color: $primary;
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled) {
    cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

    &:active,
    &.fc-button-active {
      background-color: darken($primary, 10%) !important;
      border-color: darken($primary, 10%) !important;
      @include box-shadow($btn-active-box-shadow);

      &:focus {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .fc-button-#{$color} {
    @include button-variant($value, $value);
  }
}

.fc-event-container,
.fc-list-item {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .fc-button-group {
    display: inline-grid;
  }
  .fc-button-group > .fc-button {
    border-radius: 0.25em !important;
    margin-bottom: 2px !important;
  }
}